import React from 'react'
import { InviewElement } from '../inviewElement'
import { breakpoint } from '../../shared/theme'
import styled from 'styled-components'

const Wrapper = styled.div`
	> *:first-child + section > .module {
		@media ${breakpoint('tablet')} {
			margin-top: 0;
			padding-top: 200px;
		}
	}

	> *:last-child > .module.with-background {
		margin-bottom: 0;
	}

	> *:last-child > .module:not(.with-background) {
		@media ${breakpoint('tablet')} {
			margin-bottom: 200px;
		}
	}

	.FeaturedPress + .VideoBlock {
		margin-top: 48px;

		@media ${breakpoint('tablet')} {
			margin-top: 136px;
		}
	}
`

export const InviewWrapper = ({ children }) => {
	const sections = []

	if (!Array.isArray(children)) {
		return <InviewElement name={children.type.name}>{children}</InviewElement>
	}

	children.forEach((child, index) => {
		if (!Array.isArray(child)) {
			const section = (
				<InviewElement key={`section-${index}`} name={child.type.name}>
					{child}
				</InviewElement>
			)

			sections.push(section)
		} else {
			child.forEach((moduleSection, index) => {
				const sectionModule = (
					<InviewElement
						key={`module-${index}`}
						name={moduleSection.type.name}
						test="1"
					>
						{moduleSection}
					</InviewElement>
				)

				sections.push(sectionModule)
			})
		}
	})

	return <Wrapper>{sections}</Wrapper>
}

export default InviewWrapper
