import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'
import PropTypes from 'prop-types'

export const InviewElement = ({ children, name }) => {
	const [entryActive, setActive] = useState(false)
	let clone = React.cloneElement(children, { isActive: entryActive })

	return (
		<InView
			as="section"
			onChange={(inView) => setActive(inView)}
			rootMargin={'-25% 0px -25% 0px'}
			triggerOnce={true}
			className={name || ''}
		>
			{clone}
		</InView>
	)
}

// InviewElement.propTypes = {
// 	name: PropTypes.string
// }

// InviewElement.defaultProps = {
// 	name: ''
// }
