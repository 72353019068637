import React from 'react'
import PropTypes from 'prop-types'
import P from '../../elements/typography/p'
import styled from 'styled-components'
import InviewWrapper from '../../elements/inviewWrapper'
import SectionTransition from '../../elements/activeSectionDefault'

const Wrapper = styled.div``

const ContentBlockCard = styled(SectionTransition)`
	margin-bottom: 6.44em;

	> *:last-child {
		margin-bottom: 0;
	}
`

const Subheading = styled(P)`
	font-weight: 700;
	margin-bottom: 2.75em;
`

const BlockInner = styled.div`
	p {
		margin-bottom: 2.75em;
	}

	> *:last-child {
		margin-bottom: 0;
	}
`

const ContentBlocks = ({ content }) => {
	return (
		<Wrapper>
			<InviewWrapper>
				{content.map(({ heading, copy } = block, index) => {
					return (
						<ContentBlockCard key={index}>
							{heading && <Subheading>{heading}</Subheading>}

							{copy && (
								<BlockInner dangerouslySetInnerHTML={{ __html: copy }} />
							)}
						</ContentBlockCard>
					)
				})}
			</InviewWrapper>
		</Wrapper>
	)
}

ContentBlocks.propTypes = {
	content: PropTypes.array,
}

export default ContentBlocks
