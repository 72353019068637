import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import H6 from '../../elements/typography/h6'
import { getColor, breakpoint } from '../../shared/theme'

const Header = styled.div`
	margin-top: 80px;
	padding: 120px 0 148px;

	@media ${breakpoint('tablet')} {
		margin-top: 0;
		padding: 200px 0 136px;
	}

	@media ${breakpoint('desktop')} {
		padding: 248px 0 210px;
	}
`

const Subheading = styled(H6)`
	font-weight: 700;
	text-transform: uppercase;
	display: inline-flex;
	align-items: center;
	font-size: 12px;
	margin-bottom: 30px;

	&::before {
		content: '';
		display: inline-block;
		width: 44px;
		height: 4px;
		background: ${getColor('black')};
		margin-right: 16px;
		transform: translateY(-0.025em);

		@media ${breakpoint('tablet')} {
			margin-right: 20px;
		}
	}
`

const Heading = styled.h1`
	font-weight: 700;
	font-size: 32px;
	line-height: 1.05;

	@media ${breakpoint('tablet')} {
		font-size: 48px;
	}

	@media ${breakpoint('desktop')} {
		font-size: 68px;
	}
`

const ContentHeader = ({ heading, subheading, title }) => {
	return (
		<Header>
			{subheading && <Subheading>{subheading}</Subheading>}
			<Heading dangerouslySetInnerHTML={{ __html: heading }} />
		</Header>
	)
}

ContentHeader.propTypes = {
	heading: PropTypes.string,
	title: PropTypes.string,
	subheading: PropTypes.string,
}

export default ContentHeader
