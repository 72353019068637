import React from 'react'

import SEO from '../../components/SEO'
import InviewWrapper from '../../elements/inviewWrapper'
import styled from 'styled-components'
import InnerWrapper from '../../elements/innerWrapper'
import Grid from '../../elements/grid'
import ContentHeader from '../../components/contentHeader'
import ContentBlocks from '../../components/contentBlocks'
import { getColor, breakpoint } from '../../shared/theme'

const Wrapper = styled.div`
	background: ${getColor('greyLight')};
	padding-bottom: 76px;

	@media ${breakpoint('tablet')} {
		padding-bottom: 138px;
	}
`

const WrapperInner = styled.div`
	grid-column: -1 / 1;

	@media ${breakpoint('tablet')} {
		grid-column: 3 / span 10;
	}

	@media ${breakpoint('laptop')} {
		grid-column: 3 / span 9;
	}
`

const ContactPage = ({ pageContext }) => {
	const {
		page: { title, contentFields },
	} = pageContext

	const { heading, subheading, contentSections } = contentFields

	return (
		<>
			<SEO title={title} />
			<Wrapper>
				<InnerWrapper>
					<Grid>
						<WrapperInner>
							<ContentHeader
								heading={heading}
								subheading={subheading}
								title={title}
							/>
							{contentSections && <ContentBlocks content={contentSections} />}
						</WrapperInner>
					</Grid>
				</InnerWrapper>
			</Wrapper>
		</>
	)
}

export default ContactPage
